<template>
  <site-banner pagina="principal" />

  <site-pesquisa />

  <site-tratamentos />

  <site-informacoes />

  <site-depoimentos />

  <site-noticias />

  <site-franquia />
</template>

<script setup>
const { t } = useI18n();

useSeoMeta({
  title: t("sorrifacil"),
  ogTitle: t("sorrifacil"),
  description: t("sorrifacil") + t(" especializada em implantes e ortodontia"),
  ogDescription:
    t("sorrifacil") + t(" especializada em implantes e ortodontia"),
});
</script>

<script>
export default {
  name: "IndexPagina",
};
</script>
